import React from 'react';

const SearchIcon = ({ active }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.428 22.9997L20.023 16.6358C21.0068 15.3546 21.535 13.7895 21.5277 12.1444C21.5189 10.1752 20.7421 8.3303 19.3462 6.94085C17.9502 5.5514 16.0955 4.78897 14.1287 4.79284C12.162 4.7967 10.3141 5.56886 8.93059 6.96132C7.54462 8.35378 6.78682 10.2042 6.79562 12.1733C6.80441 14.1401 7.58121 15.9899 8.97714 17.3769C10.3731 18.7663 12.2254 19.5288 14.1946 19.5249C15.8397 19.5217 17.3989 18.9833 18.6737 17.9938L25.0787 24.3553C25.0975 24.374 25.1198 24.3888 25.1442 24.3989C25.1687 24.4089 25.1949 24.4141 25.2214 24.414C25.2478 24.414 25.274 24.4087 25.2984 24.3985C25.3228 24.3884 25.3449 24.3735 25.3635 24.3547L26.4293 23.2846C26.4479 23.2658 26.4627 23.2436 26.4727 23.2191C26.4827 23.1947 26.4878 23.1685 26.4877 23.142C26.4876 23.1156 26.4822 23.0894 26.472 23.065C26.4618 23.0406 26.4468 23.0184 26.428 22.9997ZM18.0683 16.0405C17.0319 17.0811 15.6545 17.6559 14.1862 17.6588C12.7179 17.6617 11.3355 17.0923 10.2898 16.0558C9.24651 15.0167 8.66824 13.638 8.66168 12.1697C8.65512 10.7014 9.22104 9.3179 10.255 8.27725C11.2914 7.23659 12.6688 6.66178 14.1371 6.6589C15.6054 6.65601 16.9903 7.22294 18.0335 8.26196C19.0768 9.30098 19.6551 10.6798 19.6616 12.1481C19.6682 13.6164 19.1023 14.9998 18.0683 16.0405Z"
        fill={`${active ? '#FFFFFF' : '#A5A5A5'}`}
      />
    </svg>
  );
};

export default SearchIcon;
